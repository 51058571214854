import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Sidebar from "../components/job/Sidebar"
import Content from "../components/App/Content"
import Seo from "../components/App/Seo"

const JobPage = ({ data: { markdownRemark: job } }) => {
  return (
    <Layout>
      <Seo title={`Position: ${job.frontmatter.jobtitel}`}
           description={job.excerpt.replace(/\n/g, "").replace(/"/g, "'")}
           image={"/images/og/jobs-logentis-og.png"}>

        <script type="application/ld+json">{`
            { 
             "@context": "https://schema.org", 
             "@type" : "JobPosting",
             "title" : "${job.frontmatter.jobtitel}",
             "description" : "${job.html}",
             "identifier": {
                "@type": "PropertyValue",
                "name": "Logentis GmbH",
                "value": "05417807840"
             },
             "datePosted" : "2021-06-25",
             "employmentType" : "FULL_TIME",
             "hiringOrganization" : {
               "@type" : "Organization",
               "name" : "Logentis GmbH",
               "sameAs" : "https://logentis.de"
             },
             "jobLocation": {
               "@type": "Place",
               "address": {
                 "@type": "PostalAddress",
                 "streetAddress": "Franz-Lenz Str. 4",
                 "addressLocality": "Osnabrück",
                 "addressRegion": "Niedersachsen",
                 "postalCode": "49084",
                 "addressCountry": "DE"
               }
             },
             "applicantLocationRequirements": {
                "@type": "Country",
                "name": "Germany"
             },
             "jobLocationType": "TELECOMMUTE"
            } 
        `}</script>
      </Seo>
      <Navbar />
      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-desc" style={{ textAlign: "left" }}>
                <span className="sub-title">{job.frontmatter.subtitel ?? "Wir suchen dich als"}</span>
                <h1 className={"pt-3"}>{job.frontmatter.jobtitel}</h1>

                <div>
                  <ul>
                    <li><b>Ort:</b> {job.frontmatter.ort}</li>
                    <li><b>Zeitraum:</b> {job.frontmatter.zeitraum}</li>
                  </ul>
                </div>

                <div>
                  {job.frontmatter.einleitung}
                </div>

                <Content style={{ textAlign: "justify", hyphens: "auto" }} htmlContent={job.html} />
              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar job={job} />
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </Layout>
  )
}

export default JobPage

export const pageQuery = graphql`
  query jobsById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      excerpt
      frontmatter {
        jobtitel
        subtitel
        ort
        zeitraum
        einleitung
        active
        contactemail
        contactname
        contactphone
      }
      fields {
        slug
        collection
      }
      html
    }
  }
`
